#home-page {

  #o-firmie {
    padding: 80px 40px 20px;

    @media (max-width: 1599px){
      padding-left:30px;
      padding-right:30px;
    }

    @media (max-width: 1199px){
      padding:30px 30px 220px 30px;
      min-height: 0;
    }

    @media (max-width: 991px){
      padding:30px 15px 170px;
    }

    @media (max-width: 767px){
      margin-left:-15px;
      margin-right: -15px;
    }
  }

  #naturalnie-dla-twoich-dloni-i-stop .buttons-wrapper {
    .left,
    .right {
      text-align: center;
    }
  }

  #ciesz-sie-pieknem-zdrowej-skory .products-wrapper .btn-left-wrapper {
    @include media-min-max(992px, 1599px) {
      left: 0;
    }
  }
}