$firstColor: #104a8f;

body .gp-gdpr {
  &__belt {
    background-color: $firstColor;

    &-btn {
      color: #FFF;
      text-decoration: none !important;

      &:hover {
        color: $firstColor;
      }

      &--2 {
        background-color: $firstColor;
        border-color: $firstColor;
        color: #FFF;

        &:hover {
          background-color: transparent;
          border-color: $firstColor;
          color: $firstColor;
        }
      }
    }
  }

  &__popup,
  &__content {
    border-color: $firstColor;
  }

  &__icon {
    background-color: $firstColor;

    &:hover {
      background-color: darken($firstColor, 10);
    }
  }
}