body #footer {
  @include media-max(767px) {
    display: block;

    ul li {
      display: block;
      border-left: 0;
      padding: 5px 0;

      a {
        padding: 5px 0;
        display: block;
      }
    }
  }
}

.footer__menu--2 {
  margin-top: 1em;
  background-color: #093973;

  .menu-menu-legal-container {
    margin-top: 0;
  }
}