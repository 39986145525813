body {
  /*padding-top: 118px !important;*/
}

@media (max-width: 767px) {
  body {
    padding-top: 68px !important;
  }
}

@media (min-width: 1600px) {
  body .form-wrapper {
    width: 100%;
  }
}

body .flex-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

body .container-min {
  width: 1200px;
  max-width: 100%;
}

body .tags-dtxngr {
  float: left;
}

body ul {
  padding-left: 23px;
}

@media (max-width: 1599px) {
  body ul {
    padding-left: 20px;
  }
}

@media (max-width: 1199px) {
  body ul {
    padding-left: 16px;
  }
}

.gp-headline {
  color: #104a8f;
  text-transform: uppercase;
  margin: 0;
  font-weight: 900;
  font-size: 64px;
}

@media (max-width: 1599px) {
  .gp-headline {
    font-size: 50px;
  }
}

@media (max-width: 1199px) {
  .gp-headline {
    font-size: 40px;
    padding-right: 50px;
  }
}

@media (max-width: 550px) {
  .gp-headline {
    font-size: 32px;
  }
}

.btn-menu {
  display: block;
  position: relative;
  font-weight: 700;
}

.btn-menu::before {
  content: '';
  position: absolute;
  top: -10px;
  right: -25px;
  bottom: -10px;
  left: -25px;
  width: calc(100% + 50px);
  height: calc(100% + 20px);
  border: 2px solid #104a8f;
  z-index: -1;
}

.btn-menu:hover {
  color: #FFF;
}

.btn-menu:hover::before {
  background-color: #104a8f;
}

.gp-input-file {
  display: inline-block;
  position: relative;
}

.gp-input-file .wpcf7-form-control-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.gp-input-file input[type="file"] {
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gp-input-file__text {
  width: 250px;
  color: #104a8f;
  height: 39px;
  padding: 9px 15px 10px 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  border: 2px solid #104a8f;
  margin-right: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
}

@media (max-width: 420px) {
  .gp-input-file__text {
    width: calc(100% - 99px);
  }
}

.gp-input-file__btn {
  color: #FFF;
  background: #104a8f;
  padding: 9px 25px 10px 25px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
}

@media (max-width: 420px) {
  .gp-input-file__btn {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.gp-input-file__btn:hover {
  background: #1562bd;
}

body .btn-more,
body .btn-submit,
body #home-page #ciesz-sie-pieknem-zdrowej-skory .products-wrapper .btn-blue.btn-left,
body #informacje-o-leku .button-wrapper a.btn-information {
  padding: 0 30px !important;
  display: inline-block !important;
  background: #104a8f !important;
  color: #FFF !important;
  min-width: auto !important;
  transition: .3s;
}

body .btn-more:hover,
body .btn-submit:hover,
body #home-page #ciesz-sie-pieknem-zdrowej-skory .products-wrapper .btn-blue.btn-left:hover,
body #informacje-o-leku .button-wrapper a.btn-information:hover {
  background: transparent !important;
  color: #104a8f !important;
}

body #home-page #naturalnie-dla-twoich-dloni-i-stop .buttons-wrapper .btn-blue,
body #warts-page #brodacid .buttons-wrapper .btn-blue {
  text-align: center;
  min-width: auto;
  width: auto;
  background-position: left 24px center;
  padding-left: 60px;
  padding-right: 30px;
  display: inline-block;
}

body #warts-page #brodacid .buttons-wrapper .btn-blue {
  padding-top: 7px;
  padding-bottom: 7px;
}

body #informacje-o-leku .button-wrapper a.btn-information {
  padding-right: 56px !important;
}

body #informacje-o-leku .button-wrapper a.btn-information::after {
  width: 14px;
  height: 14px;
  background: 0;
  border-bottom: 2px solid #FFF;
  border-right: 2px solid #FFF;
  transform: rotate(45deg);
  top: 11px;
  right: 30px;
}

body #informacje-o-leku .button-wrapper a.btn-information:hover::after {
  border-color: #104a8f !important;
}

body #informacje-o-leku .button-wrapper a.btn-information.active::after {
  transform: rotate(-135deg);
  top: 19px;
}

@media (max-width: 767px) {
  #header {
    padding: 20px 0;
  }
}

.header__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo img {
  max-width: 250px;
  max-height: 80px;
}

#home-page #o-firmie {
  padding: 80px 40px 20px;
}

@media (max-width: 1599px) {
  #home-page #o-firmie {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1199px) {
  #home-page #o-firmie {
    padding: 30px 30px 220px 30px;
    min-height: 0;
  }
}

@media (max-width: 991px) {
  #home-page #o-firmie {
    padding: 30px 15px 170px;
  }
}

@media (max-width: 767px) {
  #home-page #o-firmie {
    margin-left: -15px;
    margin-right: -15px;
  }
}

#home-page #naturalnie-dla-twoich-dloni-i-stop .buttons-wrapper .left,
#home-page #naturalnie-dla-twoich-dloni-i-stop .buttons-wrapper .right {
  text-align: center;
}

@media screen and (min-width: 992px) and (max-width: 1599px) {
  #home-page #ciesz-sie-pieknem-zdrowej-skory .products-wrapper .btn-left-wrapper {
    left: 0;
  }
}

.bg--2 {
  background: #f1f0e8;
}

@media (max-width: 767px) {
  body #warts-page #co-to-jest-kurzajka {
    margin-left: 0;
  }
}

body #warts-page #co-to-jest-kurzajka .section-content {
  float: inherit;
  margin-bottom: 30px;
}

body #apteki .section-content ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  max-width: 1000px;
  width: 65%;
  margin-top: 50px;
  margin-left: -15px;
}

@media (max-width: 767px) {
  body #apteki .section-content ul {
    width: 81%;
  }
}

@media (max-width: 540px) {
  body #apteki .section-content ul {
    width: 75%;
  }
}

body #apteki .section-content ul li {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: 0;
}

@media (max-width: 1599px) {
  body #apteki .section-content ul li {
    padding: 15px;
  }
}

@media (max-width: 1199px) {
  body #apteki .section-content ul li {
    padding: 10px 15px;
  }
}

@media (max-width: 991px) {
  body #apteki .section-content ul li {
    width: 50%;
  }
}

@media (max-width: 540px) {
  body #apteki .section-content ul li {
    width: 100%;
  }
}

body #apteki .section-content ul li::before {
  content: none;
  display: none;
}

body #apteki .section-content ul li img {
  max-width: 100%;
  max-height: 90px;
}

body #apteki .section-content ul li img:hover {
  opacity: .7;
}

@media (max-width: 767px) {
  body #infopage {
    margin: 2em 0;
  }
}

@media (max-width: 767px) {
  body #footer {
    display: block;
  }
  body #footer ul li {
    display: block;
    border-left: 0;
    padding: 5px 0;
  }
  body #footer ul li a {
    padding: 5px 0;
    display: block;
  }
}

.footer__menu--2 {
  margin-top: 1em;
  background-color: #093973;
}

.footer__menu--2 .menu-menu-legal-container {
  margin-top: 0;
}

body .gp-gdpr__belt {
  background-color: #104a8f;
}

body .gp-gdpr__belt-btn {
  color: #FFF;
  text-decoration: none !important;
}

body .gp-gdpr__belt-btn:hover {
  color: #104a8f;
}

body .gp-gdpr__belt-btn--2 {
  background-color: #104a8f;
  border-color: #104a8f;
  color: #FFF;
}

body .gp-gdpr__belt-btn--2:hover {
  background-color: transparent;
  border-color: #104a8f;
  color: #104a8f;
}

body .gp-gdpr__popup, body .gp-gdpr__content {
  border-color: #104a8f;
}

body .gp-gdpr__icon {
  background-color: #104a8f;
}

body .gp-gdpr__icon:hover {
  background-color: #0b3261;
}
