#header {
  @include media-max(767px) {
    padding: 20px 0;
  }
}

.header {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    img {
      max-width: 250px;
      max-height: 80px;
    }
  }

  &__nav {

  }
}