body #warts-page #co-to-jest-kurzajka {
  @include media-max(767px) {
    margin-left: 0;
  }

  .section-content {
    float: inherit;
    margin-bottom: 30px;
  }
}

.warts {

}

body #apteki .section-content ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  max-width: 1000px;
  width: 65%;
  margin-top: 50px;
  margin-left: -15px;

  @include media-max(767px) {
    width: 81%;
  }

  @include media-max(540px) {
    width: 75%;
  }

  li {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin: 0;

    @include media-max(1599px) {
      padding: 15px;
    }

    @include media-max(1199px) {
      padding: 10px 15px;
    }

    @include media-max(991px) {
      width: 50%;
    }

    @include media-max(540px) {
      width: 100%;
    }

    &::before {
      content: none;
      display: none;
    }

    img {
      max-width: 100%;
      max-height: 90px;

      &:hover {
        opacity: .7;
      }
    }
  }
}