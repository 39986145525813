@mixin media-max($breakpoint) {
  @media (max-width: $breakpoint) { @content; }
}
@mixin media-min($breakpointMin) {
  @media (min-width: $breakpointMin) { @content; }
}

@mixin media-min-max($breakpointMin, $breakpointMax) {
  @media screen and (min-width: $breakpointMin) and (max-width: $breakpointMax) { @content; }
}

body {
  /*padding-top: 118px !important;*/

  @include media-max(767px) {
    padding-top: 68px !important;
  }

  .form-wrapper {
    @media (min-width: 1600px) {
      width: 100%;
    }
  }

  .flex-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .container-min {
    width: 1200px;
    max-width: 100%;
  }

  .tags-dtxngr {
    float: left;
  }

  ul {
    padding-left: 23px;

    @include media-max(1599px) {
      padding-left: 20px;
    }

    @include media-max(1199px) {
      padding-left: 16px;
    }
  }
}

.gp-headline {
  color: #104a8f;
  text-transform: uppercase;
  margin:0;
  font-weight: 900;
  font-size:64px;

  @media (max-width: 1599px){
    font-size:50px;
  }

  @media (max-width: 1199px){
    font-size:40px;
    padding-right:50px;
  }

  @media (max-width: 550px){
    font-size:32px;
  }
}

.btn-menu {
  display: block;
  position: relative;
  font-weight: 700;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: -25px;
    bottom: -10px;
    left: -25px;
    width: calc(100% + 50px);
    height: calc(100% + 20px);
    border: 2px solid #104a8f;
    z-index: -1;
  }

  &:hover {
    color: #FFF;

    &::before {
      background-color: #104a8f;
    }
  }
}

.gp-input-file {
  display: inline-block;
  position: relative;

  .wpcf7-form-control-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input[type="file"] {
    opacity: 0;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__text {
    width: 250px;
    color: #104a8f;
    height: 39px;
    padding: 9px 15px 10px 15px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 2;
    border: 2px solid #104a8f;
    margin-right: -10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;

    @include media-max(420px) {
      width: calc(100% - 99px);
    }
  }

  &__btn {
    color: #FFF;
    background: #104a8f;
    padding: 9px 25px 10px 25px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 2;
    cursor: pointer;
    pointer-events: none;

    @include media-max(420px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &:hover {
      background: lighten(#104a8f, 10);
    }
  }
}

body .btn-more,
body .btn-submit,
body #home-page #ciesz-sie-pieknem-zdrowej-skory .products-wrapper .btn-blue.btn-left,
body #informacje-o-leku .button-wrapper a.btn-information {
  padding: 0 30px !important;
  display: inline-block !important;
  background: #104a8f !important;
  color: #FFF !important;
  min-width: auto !important;
  transition: .3s;

  &:hover {
    background: transparent !important;
    color: #104a8f !important;
  }
}

body #home-page #naturalnie-dla-twoich-dloni-i-stop .buttons-wrapper .btn-blue,
body #warts-page #brodacid .buttons-wrapper .btn-blue
{
  text-align: center;
  min-width: auto;
  width: auto;
  background-position: left 24px center;
  padding-left: 60px;
  padding-right: 30px;
  display: inline-block;
}

body #warts-page #brodacid .buttons-wrapper .btn-blue {
  padding-top: 7px;
  padding-bottom: 7px;
}

body #informacje-o-leku .button-wrapper a.btn-information {
  padding-right: 56px !important;

  &::after {
    width: 14px;
    height: 14px;
    background: 0;
    border-bottom: 2px solid #FFF;
    border-right: 2px solid #FFF;
    transform: rotate(45deg);
    top: 11px;
    right: 30px;
  }

  &:hover::after {
    border-color: #104a8f !important;
  }

  &.active {
    &::after {
      transform: rotate(-135deg);
      top: 19px;
    }
  }
}